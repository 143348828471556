<script>
    import 'clickout-event'

    export default {
        props: {
            menuItems: Array,
        },
        data() {
            return {
                expanded: false,
            }
        },
        computed: {
            itemCount() {
                var count = 0

                this.menuItems.forEach(function(item) {
                    if (item.visible) {
                        ++count
                    }
                })

                return count
            },
            hasItems() {
                return this.itemCount > 0
            },
        },
        methods: {
            buttonClick(menuItem) {
                this.expanded = false
                menuItem.action()
            }
        }
    }
</script>

<template>
    <div class=component--overflow-menu v-on:clickout="expanded = false" v-show="hasItems">
        <button type=button @click="expanded = !expanded"></button>

        <ul v-show="expanded">
            <li v-for="menuItem in menuItems" :key="menuItem.label">
                <button type=button @click="buttonClick(menuItem)" :disabled="!menuItem.enabled" v-show="menuItem.visible">{{ menuItem.label }}</button>
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
    .component--overflow-menu {
        position: relative;
        z-index: 200;

        > button {
            position: relative;
            border: none;
            width: 20px;
            height: 20px;
            cursor: pointer;
            padding: 0;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                mask: url('/images/icons/ellipsis.svg') no-repeat center;
                background-color: white;
            }
        }

        > ul {
            position: absolute;
            background-color: #efefef;
            list-style: none;
            padding: 0;
            white-space: nowrap;
            font-size: 14px;

            li + li {
                border-top: 1px solid black;
            }

            button {
                display: block;
                width: 100%;
                text-align: left;
                border: none;
                background: rgba(white, 0);
                cursor: pointer;
                padding: 12px 22px;
                transition: .15s;

                &[disabled] {
                    cursor: not-allowed;
                    color: rgba(black, .3);
                }

                &:not([disabled]):hover {
                    background-color: rgba(white, .8);
                }
            }
        }
    }
</style>