import { get }  from '../ManagerApi'
import Campaign from './Campaign.js'

const defaults = {
    id                          : '',
    code                        : '',
    label                       : '',
    location                    : '',
    live_domain                 : '',
    subdomain                   : '',
    cloudflare_zone             : '',
    logo                        : '',
    colour                      : '',
    region                      : '',
    created_time                : '',
    timezone                    : '',
    lottery_local_deploy_time   : '',
    campaigns                   : [],
    deployments                 : null,
}

const Lottery = (data = {}) => {
    const lottery = Object.create(Lottery.prototype)

    Object.assign(lottery, defaults )
    Object.assign(lottery, data     )

    lottery.campaigns = lottery.campaigns.map(Campaign)

    return lottery
}

Lottery.prototype.getDeployments = async function() {
    try {
        if (!this.deployments) {
            this.deployments = (await get('/lotteries/' + this.id + '/deployments')).deployments
        }

        return this.deployments

    } catch (error) {
        return false
    }
}


export default Lottery
