<script>
    import slugify          from 'slugify'
    import { useToast }     from 'vue-toastification'
    import { getLotteries } from '../ManagerApi'
    import Site             from '../data/Site'
    import Confirm          from './Confirm.vue'
    import OverflowMenu     from './OverflowMenu.vue'
    import Modal            from './Modal.vue'
    import InputField       from './InputField.vue'
    import ToastContent     from './ToastContent.vue'

    export default {
        components: {
            OverflowMenu,
            Confirm,
            Modal,
            InputField,
        },
        data() {
            return {
                clonedSite          : null,
                clonedSiteLoading   : false,
            }
        },
        props: {
            lottery : Object,
            site    : Object,
        },
        computed: {
            authedUser() {
                return this.$store.state.authedUser
            },
            isLastDeployed() {
                return this.lottery.last_deployment && this.lottery.last_deployment.site.id == this.site.id
            },
            actionItems() {
                return [
                    {
                        label   : 'Clone Site',
                        enabled : true,
                        visible : this.authedUser && this.authedUser.can('create_sites'),
                        action  : () => this.clonedSite = new Site({ id: this.site.id, campaign: this.site.campaign })
                    },
                ]
            }
        },
        methods: {
            async recreate() {
                if (await this.$refs.confirm.open('Refresh this site?')) {
                    var recreateToast = this.toast(
                        {
                            component   : ToastContent,
                            props       : {
                                title       : 'Refreshing site...',
                                subtitle    : 'This will take a few minutes',
                            }
                        }, {
                            hideProgressBar : true              ,
                            timeout         : false             ,
                            icon            : 'lottery-loading' ,
                        }
                    )

                    this.site.recreate().then(result => {
                        if (!result) {
                            this.toast.update(recreateToast, {
                                content: 'Error refreshing site',
                                options: {
                                    closeButton : true,
                                    icon        : true,
                                    type        : 'error'
                                }
                            })
                        }

                        // now wait for the site to become available again
                        let statusCheck = setInterval(() => {
                            this.site.isOnline().then(isOnline => {
                                if (isOnline) {
                                    clearInterval(statusCheck)

                                    this.toast.update(recreateToast, {
                                        content: {
                                            component   : ToastContent,
                                            props       : {
                                                title       : 'Site refreshed!',
                                                links       : [
                                                    { title: 'Staging Preview', url: this.site.urls.frontend  },
                                                    { title: 'Staging Backend', url: this.site.urls.backend   },
                                                ]
                                            }
                                        },
                                        options: {
                                            timeout : 7000      ,
                                            icon    : true      ,
                                            type    : 'success' ,
                                        }
                                    })
                                }
                            })
                        }, 5 * 1000)
                    })
                }
            },
            async archive() {
                if (await this.$refs.confirm.open('Archive this site?')) {
                    var archiveToast = this.toast('Archiving site...', {
                        hideProgressBar : true              ,
                        timeout         : false             ,
                        icon            : 'lottery-loading' ,
                    })

                    this.site.archive().then(result => {
                        this.toast.update(archiveToast, {
                            content: (result ? 'Site archived!' : 'Error archiving site.'),
                            options: {
                                timeout     : 7000  ,
                                icon        : true  ,
                                type        : (result ? 'success' : 'error')
                            }
                        })
                    })
                }
            },
            async unarchive() {
                if (await this.$refs.confirm.open('Unarchive this site?')) {
                    var unarchiveToast = this.toast('Unarchiving site...', {
                        hideProgressBar : true              ,
                        timeout         : false             ,
                        icon            : 'lottery-loading' ,
                    })

                    this.site.unarchive().then(result => {
                        this.toast.update(unarchiveToast, {
                            content: (result ? 'Site restored!' : 'Error unarchiving site.'),
                            options: {
                                timeout     : 7000  ,
                                icon        : true  ,
                                type        : (result ? 'success' : 'error')
                            }
                        })
                    })
                }
            },
            setClonedSiteSubdomain() {
                // find the selected campaign subdomain length
                var campaignLabelLength

                this.$store.state.lotteries.forEach(lottery => {
                    lottery.campaigns.forEach(campaign => {
                        if (campaign.id == this.clonedSite.campaign) {
                            campaignLabelLength = campaign.label.length
                        }
                    })
                })

                var maxWidth    = 63 - campaignLabelLength;
                var slug        = slugify(this.clonedSite.label, { lower: true, remove: /[*+~.()'"!:@#]/g })

                this.clonedSite.subdomain = slug.substring(0, maxWidth)
            },
            async cloneSite() {
                this.clonedSiteLoading = true

                var cloneSiteToast = this.toast(
                    {
                        component   : ToastContent,
                        props       : {
                            title       : 'Cloning site...',
                            subtitle    : 'This will take a few minutes',
                        }
                    }, {
                        hideProgressBar : true              ,
                        timeout         : false             ,
                        icon            : 'lottery-loading' ,
                    }
                )

                this.clonedSite.clone().then(() => {
                    getLotteries()

                    // now wait for the site to become available
                    let statusCheck = setInterval(() => {
                        this.clonedSite.isOnline().then(isOnline => {
                            if (isOnline) {
                                clearInterval(statusCheck)

                                this.toast.update(cloneSiteToast, {
                                    content: {
                                        component   : ToastContent,
                                        props       : {
                                            title       : 'Site cloned!',
                                            links       : [
                                                { title: 'Staging Preview', url: this.clonedSite.urls.frontend  },
                                                { title: 'Staging Backend', url: this.clonedSite.urls.backend   },
                                            ]
                                        }
                                    },
                                    options: {
                                        timeout     : 7000      ,
                                        icon        : true      ,
                                        type        : 'success' ,
                                    }
                                })

                                this.clonedSite         = null
                                this.clonedSiteLoading  = false
                            }
                        })
                    }, 5 * 1000)
                })
            }
        },
        setup() {
            const toast = useToast()
            return { toast }
        },
    }
</script>

<template>
    <div class=component--site-item>
        <a :href="site.urls.backend" class=backend data-tooltip="Staging Admin" v-show="!site.is_archived" :data-archived="site.is_archived"></a>

        <h5>
            <a v-if="!site.is_archived" :href="site.urls.frontend" data-tooltip="Staging Preview">{{ site.label }}</a>
            <span v-else>{{ site.label }}</span>

            <span class=last-deployed v-if="isLastDeployed" data-tooltip="This is the current live site"><span></span></span>
        </h5>

        <div>
            <button type=button class=archive   data-tooltip="Archive Site"     @click="archive"    v-show="!site.is_archived && authedUser && authedUser.can('archive_sites')"></button>
            <button type=button class=unarchive data-tooltip="Unarchive Site"   @click="unarchive"  v-show=" site.is_archived && authedUser && authedUser.can('archive_sites')"></button>
            <button type=button class=refresh   data-tooltip="Refresh Site"     @click="recreate"   v-show="!site.is_archived && authedUser && authedUser.can('refresh_sites')"></button>
            <overflow-menu :menuItems="actionItems" />
        </div>

        <confirm ref=confirm />

        <modal class=modal-clone-site v-if="clonedSite && !clonedSiteLoading" @click="clonedSite = null">
            <h2>Cloning {{ site.label }}</h2>

            <form @submit.prevent="cloneSite" :class="{ 'is--loading': clonedSiteLoading }">
                <label class=component--input-field>
                    <span class=label>Program to create the cloned site in</span>

                    <select v-model="clonedSite.campaign">
                        <template v-for="lottery in $store.state.lotteries" :key="lottery.id">
                            <optgroup :label="lottery.code + ' ' + lottery.label">
                                <template v-for="campaign in lottery.campaigns" :key="campaign.id">
                                    <option :value="campaign.id">{{ campaign.label }}</option>
                                </template>
                            </optgroup>
                        </template>
                    </select>
                </label>

                <input-field label="Label" placeholder="eg. Winners" :required="true" v-model="clonedSite.label" @input="setClonedSiteSubdomain" />
                <input-field label="Staging Subdomain" placeholder="eg. winners" :required="true" v-model="clonedSite.subdomain" />

                <button>Clone Site</button>
            </form>
        </modal>
    </div>
</template>

<style lang="scss">
    .component--site-item {
        display: flex;
        margin: 5px 0;

        .last-deployed {
            position: relative;
            content: '';
            width: 1em;
            height: 100%;
            margin-left: 4px;

            span:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #10D300;
            }
        }

        h5 {
            display: flex;
            align-items: center;
            margin: 0 8px;

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        > div {
            display: flex;
            align-items: center;
            margin-left: auto;

            button {
                margin-left: 8px;
            }
        }

        a.backend,
        .archive,
        .unarchive,
        .refresh {
            display: block;
            width: 16px;
            height: 16px;
            background: no-repeat center;
            background-size: contain;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 0;
        }

        a.backend {
            background-image: url('/images/icons/pencil.svg');
        }

        .archive {
            background-image: url('/images/icons/archive.svg');
        }

        .unarchive {
            background-image: url('/images/icons/box-open.svg');
        }

        .refresh {
            background-image: url('/images/icons/sync.svg');
        }

        .component--overflow-menu {
            display: flex;
            align-items: center;

            button {
                right: -5px;
                background: none;
                margin-left: 0;

                &:before {
                    background-color: black;
                    mask-position: calc(100% - 5px);
                }
            }

            ul {
                top: 20px;
            }
        }
    }
</style>