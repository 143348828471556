<script>
    export default {
        props: {
            label       : String    ,
            modelValue  : String    ,
            required    : Boolean   ,
            description : String    ,
            placeholder : {
                type        : String,
                default     : '',
            },
            type        : {
                type        : String,
                default     : 'text',
            }
        }
    }
</script>

<template>
    <label class=component--input-field>
        <span class=label>{{ label }}</span>
        <input :type="type" :required="required" :value="modelValue" :placeholder="placeholder" @input="$emit('update:modelValue', $event.target.value)">
        <span class=desc v-show="description">{{ description }}</span>
    </label>
</template>

<style lang="scss">
    .component--input-field {
        display: block;
        margin: 1rem 0;

        .label {
            display: block;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: .2em;
            margin-bottom: 5px;
        }

        select,
        textarea,
        input {
            display: block;
            width: 100%;
            background-color: rgba(white, 0);
            border: 1px solid black;
            padding: .7rem 1rem;
            transition: .15s;

            &:focus {
                outline: none;
                background-color: white;
            }
        }

        .desc {
            font-size: 12px;
        }
    }
</style>
