<script>
    import slugify          from 'slugify'
    import { useToast }     from 'vue-toastification'
    import { getLotteries } from '../ManagerApi'
    import Site             from '../data/Site'
    import SiteItem         from './SiteItem.vue'
    import OverflowMenu     from './OverflowMenu.vue'
    import Confirm          from './Confirm.vue'
    import Modal            from './Modal.vue'
    import InputField       from './InputField.vue'
    import ToastContent     from './ToastContent.vue'

    export default {
        components: {
            OverflowMenu,
            Confirm,
            SiteItem,
            Modal,
            InputField,
        },
        data() {
            return {
                newSite         : null,
                newSiteLoading  : false,
            }
        },
        props: {
            lottery         : Object,
            campaign        : Object,
            archivedSites   : {
                type            : Boolean   ,
                default         : false     ,
            }
        },
        computed: {
            authedUser() {
                return this.$store.state.authedUser
            },
            sites() {
                var vm      = this
                var sites   = []

                this.campaign.sites.forEach(site => {
                    if ((!site.is_archived && !vm.archivedSites) || (site.is_archived && vm.archivedSites)) {
                        sites.push(site)
                    }
                })

                return sites
            },
            actionItems() {
                var items = []

                if (!this.campaign.is_archived) {
                    items.push({
                        label   : 'Archive Campaign',
                        enabled : true,
                        visible : this.authedUser && this.authedUser.can('archive_sites'),
                        action  : () => this.archive()
                    })

                } else {
                    items.push({
                        label   : 'Unarchive Campaign',
                        enabled : true,
                        visible : this.authedUser && this.authedUser.can('archive_sites'),
                        action  : () => this.unarchive()
                    })
                }

                items.push({
                    label   : 'Create New Site',
                    enabled : true,
                    visible : this.authedUser && this.authedUser.can('create_sites'),
                    action  : () => this.newSite = new Site({ campaign: this.campaign.id })
                })

                return items
            }
        },
        methods: {
            async archive() {
                var vm = this

                if (await this.$refs.confirm.open('Archive this campaign?')) {
                    var archiveToast = vm.toast('Archiving campaign...', {
                        hideProgressBar : true              ,
                        timeout         : false             ,
                        icon            : 'lottery-loading' ,
                    })

                    vm.campaign.archive().then(result => {
                        vm.toast.update(archiveToast, {
                            content: (result ? 'Campaign archived!' : 'Error archiving campaign.'),
                            options: {
                                timeout     : 7000  ,
                                icon        : true  ,
                                type        : (result ? 'success' : 'error')
                            }
                        })
                    })
                }
            },
            unarchive() {
                var vm = this

                var unarchiveToast = vm.toast('Unarchiving campaign...', {
                    hideProgressBar : true              ,
                    timeout         : false             ,
                    icon            : 'lottery-loading' ,
                })

                vm.campaign.unarchive().then(result => {
                    vm.toast.update(unarchiveToast, {
                        content: (result ? 'Campaign restored!' : 'Error unarchiving campaign.'),
                        options: {
                            timeout     : 7000  ,
                            icon        : true  ,
                            type        : (result ? 'success' : 'error')
                        }
                    })
                })
            },
            setNewSiteSubdomain() {
                var maxWidth    = 63 - this.campaign.subdomain.length;
                var slug        = slugify(this.newSite.label, { lower: true, remove: /[*+~.()'"!:@#]/g })

                this.newSite.subdomain = slug.substring(0, maxWidth)
            },
            async createSite() {
                this.newSiteLoading = true

                var newSiteToast = this.toast(
                    {
                        component   : ToastContent,
                        props       : {
                            title       : 'Creating site...',
                            subtitle    : 'This will take a few minutes',
                        }
                    }, {
                        hideProgressBar : true              ,
                        timeout         : false             ,
                        icon            : 'lottery-loading' ,
                    }
                )

                this.newSite.create().then(() => {
                    getLotteries()

                    // now wait for the site to become available
                    let statusCheck = setInterval(() => {
                        this.newSite.isOnline().then(isOnline => {
                            if (isOnline) {
                                clearInterval(statusCheck)

                                this.toast.update(newSiteToast, {
                                    content: {
                                        component   : ToastContent,
                                        props       : {
                                            title       : 'Site created!',
                                            links       : [
                                                { title: 'Staging Preview', url: this.newSite.urls.frontend  },
                                                { title: 'Staging Backend', url: this.newSite.urls.backend   },
                                            ]
                                        }
                                    },
                                    options: {
                                        timeout     : 7000      ,
                                        icon        : true      ,
                                        type        : 'success' ,
                                    }
                                })

                                this.newSiteLoading = false
                                this.newSite        = null
                            }
                        })
                    }, 5 * 1000)
                })
            }
        },
        setup() {
            const toast = useToast()
            return { toast }
        },
    }
</script>

<template>
    <div class=component--campaign-item>
        <div>
            <h4>{{ campaign.label }}</h4>
            <overflow-menu :menuItems="actionItems" />
        </div>

        <ul>
            <li v-for="site in sites" :key="site.id">
                <site-item :site="site" :lottery="lottery" />
            </li>
        </ul>

        <confirm ref=confirm />

        <modal class=modal-create-site v-if="newSite && !newSiteLoading" @click="newSite = null">
            <h2>Create a New Site</h2>

            <form @submit.prevent="createSite">
                <input-field label="Label" placeholder="eg. Winners" :required="true" v-model="newSite.label" @input="setNewSiteSubdomain" />
                <input-field label="Staging Subdomain" placeholder="eg. winners" :required="true" v-model="newSite.subdomain" />

                <button>Create Site</button>
            </form>
        </modal>
    </div>
</template>

<style lang="scss">
    .component--campaign-item {
        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4 {
                text-transform: uppercase;
            }

            .component--overflow-menu {
                display: flex;
                align-items: center;
                z-index: 300;

                button {
                    right: -5px;
                    background: none;

                    &:before {
                        background-color: black;
                        mask-position: calc(100% - 5px);
                    }
                }

                ul {
                    top: 20px;
                }
            }
        }

        > ul {
            list-style: none;
            padding: 0;

            > li:not(:last-child) {
                border-bottom: 1px solid;
            }

            > li {
                position: relative;

                &:hover {
                    z-index: 1000;
                }
            }
        }
    }
</style>