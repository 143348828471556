import { post, put, patch }  from '../ManagerApi'
import Permission   from './Permission.js'
import Lottery      from './Lottery.js'
import TimeAgo      from '../TimeAgo'

const defaults = {
    id                  : '',
    email               : '',
    first_name          : '',
    last_name           : '',
    organisation        : '',
    req_new_pass        :  0,
    account_enabled     :  1,
    date_registered     : '',
    last_auth           : '',
    last_auth_ago       : 0 ,
    permissions         : [],
    lotteries           : [],
    tmp_password        : '',
    '2fa_enabled'       : false,
    reset_totp_secret   : false,
}

const User = (data = {}) => {
    const user = Object.create(User.prototype)

    Object.assign(user, defaults)
    Object.assign(user, data    )

    user.permissions    = user.permissions.map(Permission)
    user.lotteries      = user.lotteries.map(Lottery)

    user.last_auth_ago = TimeAgo.inWords(user.last_auth_ago)

    return user
}

User.prototype.isAdministrator = function() {
    for (let i = 0; i < this.permissions.length; i++) {
        if (this.permissions[i].tag == 'administrator') {
            return true
        }
    }

    return false
}

User.prototype.canAccessAllLotteries = function() {
    for (let i = 0; i < this.permissions.length; i++) {
        if (this.permissions[i].tag == 'administrator' || this.permissions[i].tag == 'access_all_lotteries') {
            return true
        }
    }

    return false
}

User.prototype.create = async function() {
    try {
        const response = await post('/users', { user: this })

        this.id = response.user.id

        return true

    } catch (error) {
        return false
    }
}

User.prototype.update = async function() {
    try {
        await patch('/users/' + this.id, {
            attributes: {
                email               : this.email            ,
                first_name          : this.first_name       ,
                last_name           : this.last_name        ,
                organisation        : this.organisation     ,
                req_new_pass        : this.req_new_pass     ,
                account_enabled     : this.account_enabled  ,
                tmp_password        : this.tmp_password     ,
                reset_totp_secret   : this.reset_totp_secret,
            }
        })

        return true

    } catch (error) {
        return false
    }
}

User.prototype.setPermissions = async function() {
    try {
        await put('/users/' + this.id + '/permissions', { permissions: this.permissions })

        return true

    } catch (error) {
        return false
    }
}

User.prototype.setLotteries = async function() {
    try {
        await put('/users/' + this.id + '/lotteries', { lotteries: this.lotteries })

        return true

    } catch (error) {
        return false
    }
}

User.prototype.can = function(permission_tag) {
    for (var i = 0; i < this.permissions.length; i++) {
        let permission = this.permissions[i]

        if (permission.tag == 'administrator' || permission.tag == permission_tag) {
            return true
        }
    }

    return false
}

User.prototype.logOut = async function() {
    try {
        await post('/auth/logout')
        window.location.reload()

    } catch (error) {
        window.location.reload()
    }

}

export default User