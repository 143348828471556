<script>
    export default {
        data() {
            return {
                active  : false,
                resolve : null,
                reject  : null,
                message : null,
                options : {
                    yesText : 'Yes',
                    noText  : 'No',
                }
            }
        },
        methods: {
            open(message, options) {
                this.active     = true
                this.message    = message
                this.options    = Object.assign(this.options, options)

                return new Promise((resolve, reject) => {
                    this.resolve    = resolve
                    this.reject     = reject
                })
            },
            affirmative() {
                this.active = false
                this.resolve(true)
            },
            negative() {
                this.active = false
                this.resolve(false)
            }
        },
    }
</script>

<template>
    <div class=component--confirm v-show="active">
        <div>
            <p>{{ message }}</p>

            <div class=actions>
                <button @click="negative"   >{{ options.noText }}</button>
                <button @click="affirmative">{{ options.yesText }}</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .component--confirm {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(black, .5);
        z-index: 500;

        .component--modal & {
            position: absolute;
            top: -999rem;
            bottom: -999rem;
            left: -30px;
            right: -30px;
        }

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #efefef;
            padding: 30px;
        }

        p {
            text-align: center;
            font-size: 1.2;
        }

        .actions {
            display: flex;
            justify-content: center;
            margin-top: 2rem;

            button {
                margin: 0 21px;
                border: 1px solid #272B2D;
                font-weight: bold;
                padding: 8px 30px;
                cursor: pointer;

                &:first-child {
                    background-color: transparent;
                }

                &:last-child {
                    background-color: #272B2D;
                    color: #efefef;
                }
            }
        }
    }
</style>