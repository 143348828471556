<script>
    import { useToast } from 'vue-toastification'
    import slugify from 'slugify'
    import 'clickout-event'
    import { getLotteries } from '../ManagerApi'
    import Campaign     from '../data/Campaign'
    import CampaignItem from './CampaignItem.vue'
    import OverflowMenu from './OverflowMenu.vue'
    import Modal        from './Modal.vue'
    import InputField   from './InputField.vue'

    export default {
        components: {
            CampaignItem,
            OverflowMenu,
            Modal,
            InputField,
        },
        props: {
            lottery: Object
        },
        data() {
            return {
                showArchive         : false,
                showDeployments     : false,
                deployments         : null,
                newCampaign         : null,
                newCampaignLoading  : false,
            }
        },
        computed: {
            authedUser() {
                return this.$store.state.authedUser
            },
            colour() {
                return this.lottery.colour || '#cccccc'
            },
            activeCampaigns() {
                var campaigns = []

                this.lottery.campaigns.forEach(campaign => {
                    if (!campaign.is_archived) {
                        campaigns.push(campaign)
                    }
                })

                return campaigns
            },
            archivedCampaigns() {
                var campaigns = []

                this.lottery.campaigns.forEach(campaign => {
                    if (campaign.is_archived) {
                        campaigns.push(campaign)
                    }

                    let hasArchivedSite = false

                    campaign.sites.forEach(site => {
                        if (site.is_archived) {
                            hasArchivedSite = true
                        }
                    })

                    if (hasArchivedSite) {
                        campaigns.push(campaign)
                    }
                })

                return campaigns
            },
            actionItems() {
                return [
                    {
                        label   : 'View Archived Sites',
                        enabled : this.archivedCampaigns.length > 0,
                        visible : this.authedUser && this.authedUser.can('archive_sites'),
                        action: () => this.showArchive = true
                    },
                    {
                        label   : 'Deployment History',
                        enabled : true,
                        visible : this.authedUser && this.authedUser.can('publish'),
                        action  : () => {
                            this.getDeployments()
                            this.showDeployments = true
                        }
                    },
                    {
                        label   : 'Create New Program',
                        enabled : true,
                        visible : this.authedUser && this.authedUser.can('create_campaigns'),
                        action  : () => this.newCampaign = new Campaign({ lottery: this.lottery.id })
                    }
                ]
            },
        },
        methods: {
            getDeployments() {
                this.lottery.getDeployments().then(deployments => {
                    this.deployments = deployments
                })
            },
            setNewCampaignSubdomain() {
                this.newCampaign.subdomain = slugify(this.newCampaign.label, { lower: true, remove: /[*+~.()'"!:@#]/g })
            },
            async createProgram() {
                this.newCampaignLoading = true
                await this.newCampaign.create()
                await getLotteries()
                this.newCampaign = null
                this.newCampaignLoading = false

                this.toast('Program created!', {
                    timeout : 7000      ,
                    icon    : true      ,
                    type    : 'success' ,
                })
            }
        },
        setup() {
            const toast = useToast()
            return { toast }
        },
    }
</script>

<template>
    <div class=component--lottery-item :style="`--colour: ${colour}`">
        <figure>
            <img :src="`images/logos/${lottery.code}.png`">

            <div>
                <overflow-menu :menuItems="actionItems" />
                <a class=live-site :href="`https://${lottery.live_domain}/`" data-tooltip="View live site"></a>
            </div>
        </figure>

        <h3>{{ lottery.code }} {{ lottery.label }}</h3>

        <ul class=campaigns>
            <li v-for="campaign in activeCampaigns" :key=campaign.id>
                <campaign-item :campaign="campaign" :lottery="lottery" />
            </li>
        </ul>

        <modal class=modal-archive v-show="showArchive" @click="showArchive = false">
            <ul class=campaigns>
                <li v-for="campaign in archivedCampaigns" :key=campaign.id>
                    <campaign-item :campaign="campaign" :lottery="lottery" :archivedSites="true" />
                </li>
            </ul>
        </modal>

        <modal class=modal-deployments v-show="showDeployments" @click="showDeployments = false">
            <h2>Deployment Log</h2>
            <h4>Times are in the local lottery time ({{ lottery.timezone }})</h4>

            <div v-if="!deployments" class=loading></div>

            <table v-else>
                <thead>
                    <tr>
                        <th>Campaign</th>
                        <th>Site</th>
                        <th>Deployer</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tr v-for="deployment in deployments" :key="deployment.id">
                    <td>{{ deployment.campaign.label }}</td>
                    <td>{{ deployment.site.label }}</td>
                    <td>{{ deployment.user.first_name }} {{ deployment.user.last_name }}</td>
                    <td>{{ deployment.lottery_local_deploy_time }}</td>
                </tr>
            </table>
        </modal>

        <modal class=modal-create-campaign v-if="newCampaign" @click="newCampaign = null">
            <h2>Create a New Program</h2>

            <form @submit.prevent="createProgram" :class="{ 'is--loading': newCampaignLoading }">
                <input-field label="Label" placeholder="eg. Winter 2022" :required="true" v-model="newCampaign.label" @input="setNewCampaignSubdomain" />
                <input-field label="Staging Subdomain" placeholder="eg. winter-2022" :required="true" v-model="newCampaign.subdomain" />

                <button>Create Program</button>
            </form>
        </modal>
    </div>
</template>

<style lang="scss">
    .component--lottery-item {
        figure {
            position: relative;
            padding-bottom: 75%;
            margin: 0;
            background-color: var(--colour);

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 70%;
                max-height: 50%;
            }

            > div {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 10;

                > * {
                    margin-bottom: 10px;
                }

                .component--overflow-menu {
                    > button {
                        border-radius: 50%;
                        background-color: rgba(#eee, .3);
                    }
                }
            }

            .live-site {
                border-radius: 50%;
                width: 20px;
                height: 20px;
                background: rgba(#eee, .3) no-repeat center url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJleHRlcm5hbC1saW5rLWFsdCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWV4dGVybmFsLWxpbmstYWx0IGZhLXctMTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTQzMiwzMjBINDAwYTE2LDE2LDAsMCwwLTE2LDE2VjQ0OEg2NFYxMjhIMjA4YTE2LDE2LDAsMCwwLDE2LTE2VjgwYTE2LDE2LDAsMCwwLTE2LTE2SDQ4QTQ4LDQ4LDAsMCwwLDAsMTEyVjQ2NGE0OCw0OCwwLDAsMCw0OCw0OEg0MDBhNDgsNDgsMCwwLDAsNDgtNDhWMzM2QTE2LDE2LDAsMCwwLDQzMiwzMjBaTTQ4OCwwaC0xMjhjLTIxLjM3LDAtMzIuMDUsMjUuOTEtMTcsNDFsMzUuNzMsMzUuNzNMMTM1LDMyMC4zN2EyNCwyNCwwLDAsMCwwLDM0TDE1Ny42NywzNzdhMjQsMjQsMCwwLDAsMzQsMEw0MzUuMjgsMTMzLjMyLDQ3MSwxNjljMTUsMTUsNDEsNC41LDQxLTE3VjI0QTI0LDI0LDAsMCwwLDQ4OCwwWiI+PC9wYXRoPjwvc3ZnPg==");
                background-size: 10px;
            }
        }

        > ul {
            list-style: none;
            padding: 0;
        }

        .modal-archive {
            .campaigns {
                padding: 0;
                list-style: none;
            }
        }

        .modal-deployments {
            h4 {
                text-align: center;
                margin-top: -1rem;
            }
        }

        @media (max-width: 430px) {
            h3 {
                font-size: 1rem;
            }
        }
    }
</style>