import { get, post }  from '../ManagerApi'

const defaults = {
    id          : '',
    label       : '',
    campaign    : '',
    subdomain   : '',
    tag         : 'master',
    is_archived : false,
    urls        : {
        frontend    : '',
        backend     : '',
    },
}

const Site = (data = {}) => {
    const site = Object.create(Site.prototype)

    Object.assign(site, defaults)
    Object.assign(site, data    )

    return site
}

Site.prototype.create = async function() {
    try {
        const response = await post('/sites', {
            campaign    : this.campaign ,
            label       : this.label    ,
            subdomain   : this.subdomain,
        })

        this.id     = response.site.id
        this.urls   = response.site.urls

        return true

    } catch (error) {
        return false
    }
}

Site.prototype.clone = async function() {
    try {
        const response = await post('/sites/' + this.id + '/clone', {
            campaign    : this.campaign ,
            label       : this.label    ,
            subdomain   : this.subdomain,
        })

        this.id     = response.site.id
        this.urls   = response.site.urls

        return true

    } catch (error) {
        return false
    }
}

Site.prototype.recreate = async function() {
    try {
        var params = new URLSearchParams()
        params.append('id', this.id)

        await post('/sites/container/recreate', params)

        return true

    } catch (error) {
        return false
    }
}

Site.prototype.archive = async function() {
    try {
        await post('/sites/' + this.id + '/archive')

        this.is_archived = true

        return true

    } catch (error) {
        return false
    }
}

Site.prototype.unarchive = async function() {
    try {
        await post('/sites/' + this.id + '/unarchive')

        this.is_archived = false

        return true

    } catch (error) {
        return false
    }
}

Site.prototype.isOnline = async function() {
    try {
        const response = await get('/sites/' + this.id + '/status')

        return response.online

    } catch (error) {
        return false
    }
}


export default Site