<script>
    export default {
        props: {
            title   : {
                type    : String,
                required: true,
            },
            subtitle: {
                type    : String,
                default : '',
            },
            links   : {
                type    : Array,
                default : () => [],
            },
        },
    }
</script>

<template>
    <div class=component--toast-content>
        <span>{{ title }}</span>
        <span v-show="subtitle">{{ subtitle }}</span>

        <ul v-if="links.length">
            <li v-for="link in links" :key="link.title">
                <a :href="link.url">{{ link.title }}</a>
            </li>
        </ul>
    </div>
</template>

<style lang=scss>
    .component--toast-content {
        span {
            display: block;

            &:last-child {
                font-size: 12px;
            }
        }

        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 12px;

            li + li {
                &:before {
                    content: '|';
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

            a {
                text-decoration: underline;
            }
        }
    }
</style>