const defaults = {
    id          : '',
    tag         : '',
    description : '',
}

const Permission = (data = {}) => {
    const permission = Object.create(Permission.prototype)

    Object.assign(permission, defaults)
    Object.assign(permission, data    )

    return permission
}

export default Permission