<script>
    import LotteryItem  from '../components/LotteryItem.vue'
    import Fuse         from '../../node_modules/fuse.js'

    export default {
        components: {
            LotteryItem
        },
        data() {
            return {
                filteredRegion      : ''    ,
                searchVal           : ''    ,
                newLottery          : null  ,
                newLotteryLoading   : false ,
            }
        },
        computed: {
            lotteries() {
                return this.$store.state.lotteries
            },
            apiUrl() {
                return process.env.VUE_APP_API_URL
            },
            authedUser() {
                return this.$store.state.authedUser
            },
            regions() {
                var regions = []

                if (this.lotteries) {
                    this.lotteries.forEach(lottery => {
                        if (regions.indexOf(lottery.region) === -1) {
                            regions.push(lottery.region)
                        }
                    })
                }

                return regions
            },
            filteredLotteries() {
                var lotteries = []

                if (this.lotteries) {
                    this.lotteries.forEach(lottery => {
                        if (this.filteredRegion == '' || this.filteredRegion == lottery.region) {
                            lotteries.push(lottery)
                        }
                    })
                }

                if (!this.searchVal) {
                    return lotteries
                }

                let fuse = new Fuse(lotteries, {
                    keys: [ 'label', 'code', 'location', 'region', 'campaigns.label' ]
                })

                let resultItems = fuse.search(this.searchVal)
                let results     = []

                resultItems.forEach(item => {
                    results.push(item.item)
                })

                return results
            }
        },
    }
</script>

<template>
    <div class=view--sites :class="{ 'is--loading': lotteries == null }">
        <div class=filters v-if="lotteries != null">
            <ul class=filters>
                <li :class="{ active: filteredRegion == '' }"><button type=button @click="filteredRegion = ''">All</button></li>

                <li v-for="region in regions" :key="region" :class="{ active: filteredRegion == region }">
                    <button type=button @click="filteredRegion = region">{{ region }}</button>
                </li>
            </ul>

            <input type=search v-model="searchVal">
        </div>

        <ul class=lotteries v-if="lotteries != null">
            <li v-for="lottery in filteredLotteries" :key="lottery.id">
                <LotteryItem :lottery="lottery" />
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
    .view--sites {
        div.filters {
            display: flex;
            justify-content: space-between;
            align-items: center;

            ul {
                display: flex;
                align-items: center;
                list-style: none;
                padding: 0;
                margin: 3rem 0;

                &:before {
                    content: 'Filters';
                    text-transform: uppercase;
                    opacity: .5;

                    @media (max-width: 640px) {
                        content: none;
                    }
                }

                li {
                    margin-left: 20px;

                    @media (max-width: 860px) {
                        &:not(:first-child) {
                            margin-left: 0;
                        }
                    }

                    @media (max-width: 640px) {
                        margin-left: 0;
                    }
                }

                button {
                    padding: 10px 20px;
                    border: 1px solid rgba(black, 0);
                    background: none;
                    transition: .15s;
                    cursor: pointer;

                    @media (max-width: 760px) {
                        padding: 6px 14px;
                    }
                }

                .active button {
                    border-color: black;
                }
            }

            input {
                border: solid black;
                border-width: 0 0 1px;
                width: calc(25% - 30px);
                min-width: 100px;
                background: url('/images/icons/search.svg') no-repeat right;
                background-size: auto calc(100% - 8px);
                padding: 5px;
                outline: none;
            }

            @media (max-width: 560px) {
                flex-direction: column;

                ul,
                input {
                    width: 100%;
                }

                ul {
                    margin: 2rem 0 1rem;
                    justify-content: space-between;
                }

                input {
                    margin-bottom: 2rem;
                }
            }
        }

        .lotteries {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            list-style: none;
            gap: var(--padding);
            padding: 0;

            @media (max-width: 1024px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width: 760px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
</style>
