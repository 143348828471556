import { post } from '../ManagerApi'
import Site     from './Site.js'

const defaults = {
    id          : '',
    label       : '',
    subdomain   : '',
    lottery     : null,
    is_archived : false,
    sites       : [],
}

const Campaign = (data = {}) => {
    const campaign = Object.create(Campaign.prototype)

    Object.assign(campaign, defaults )
    Object.assign(campaign, data     )

    campaign.sites = campaign.sites.map(Site)

    return campaign
}

Campaign.prototype.create = async function() {
    try {
        const response = await post('/campaigns', {
            lottery     : this.lottery  ,
            label       : this.label    ,
            subdomain   : this.subdomain,
        })

        this.id = response.campaign.id

        return true

    } catch (error) {
        return false
    }
}

Campaign.prototype.archive = async function() {
    try {
        await post('/campaigns/' + this.id + '/archive')

        this.sites.forEach(site => {
            site.is_archived = true
        })

        this.is_archived = true

        return true

    } catch (error) {
        return false
    }
}

Campaign.prototype.unarchive = async function() {
    try {
        await post('/campaigns/' + this.id + '/unarchive')

        this.is_archived = false

        return true

    } catch (error) {
        return false
    }
}

export default Campaign