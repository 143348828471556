import { createStore } from 'vuex'

export default createStore({
    state() {
        return {
            isAuthed    : null,
            authedUser  : null,
            lotteries   : null,
        }
    },
    mutations: {
        setAuthedUser(state, user) {
            state.isAuthed      = true
            state.authedUser    = user
        },
        setIsAuthed(state, isAuthed) {
            state.isAuthed = isAuthed
        },
        setLotteries(state, lotteries) {
            state.lotteries = lotteries
        }
    }
})
