<script>
    export default {

    }
</script>

<template>
    <div class=component--modal>
        <div @click.stop>
            <slot />
        </div>
    </div>
</template>

<style lang="scss">
    .component--modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(black, .5);
        z-index: 400;

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 620px;
            min-height: 140px;
            padding: 30px;
            max-width: calc(100vw - 60px);
            max-height: calc(100vh - 200px);
            background-color: #efefef;
            overflow: auto;

            > :first-child {
                margin-top: 0;
            }

            > h2:first-child {
                text-align: center;
            }

            .loading {
                width: var(--padding);
                height: var(--padding);
                border-width: 6px;
                margin-top: var(--padding);
            }

            .component--overflow-menu > ul {
                right: -20px;
            }

            [data-tooltip] {
                &:before {
                    left: 30px;
                    transform: translate(-100%, 100%);
                }
            }
        }

        .campaigns {
            padding: 0;
            list-style: none;
        }

        table {
            width: 100%;
        }

        form button {
            position: relative;
            display: block;
            margin: 2rem auto 0;
            background-color: #efefef;

            &:hover {
                background-color: white;
            }
        }

        form.is--loading button {
            color: rgba(black, 0);
            background-color: #efefef !important;
        }
    }
</style>