import axios    from 'axios'
import Store    from './store'
import Lottery  from './data/Lottery'
import User     from './data/User'

async function getAuthedUser() {
    try {
        const user = await get('/users/self')
        Store.commit('setAuthedUser', User(user.user))

        return true

    } catch (error) {
        if (error.response && error.response.status == 401) {
            Store.commit('setIsAuthed', false)
        }

        return false
    }
}

async function getLotteries() {
    const lotteries = await get('/lotteries/children')
    Store.commit('setLotteries', lotteries.lotteries.map(Lottery))
}

async function get(endpoint) {
    const response = await request('get', endpoint, {})
    return response.data
}

async function post(endpoint, data) {
    const response = await request('post', endpoint, data)
    return response.data
}

async function patch(endpoint, data) {
    const response = await request('patch', endpoint, data)
    return response.data
}

async function put(endpoint, data) {
    const response = await request('put', endpoint, data)
    return response.data
}

async function request(method, endpoint, data) {
    var apiUrl = process.env.VUE_APP_API_URL

    const response = await axios({
        method          : method,
        url             : apiUrl + endpoint,
        data            : data,
        withCredentials : true
    })

    return response
}

export { getAuthedUser, getLotteries, get, post, put, patch }
