<script>
    import { getLotteries, getAuthedUser } from './ManagerApi'

    export default {
        name: 'lotteries.dev',
        data() {
            return {
                showNav: false,
            }
        },
        computed: {
            apiUrl() {
                return process.env.VUE_APP_API_URL
            },
            authed() {
                return this.$store.state.isAuthed
            },
            authedUser() {
                return this.$store.state.authedUser
            },
        },
        methods: {
            async getData() {
                const auth = await getAuthedUser()

                if (auth) {
                    await getLotteries()
                }
            },
        },
        mounted() {
            var vm = this

            // lottery manager sends a message when the user is authed
            window.addEventListener('message', function(event) {
                if (vm.apiUrl.indexOf(event.origin) != -1) {
                    console.log(event)
                    vm.$store.commit('setIsAuthed', true)
                    vm.getData()
                }
            })

            vm.getData()
        }
    }
</script>

<template>
    <div>
        <div class=loading v-if="!authed"></div>

        <div id=manager :class="{ visible: authed === false }">
            <iframe :src="`${apiUrl}/auth`" scrolling=no></iframe>
        </div>

        <header v-if="authed">
            <router-link to="/">
                <img src="images/logo.png" alt="S.O. Asher">
            </router-link>

            <button @click="showNav = !showNav"></button>

            <nav v-show="showNav" @click="showNav = !showNav">
                <router-link to="/">View Lotteries</router-link>
                <router-link to="/users" v-show="authed && authedUser.can('manage_users')">Manage Users</router-link>
                <button @click="authedUser.logOut()">Log Out</button>
            </nav>
        </header>

        <main v-if="authed">
            <router-view />
        </main>

        <div class=confirm></div>
    </div>
</template>

<style lang="scss">
    @import 'styles/global';

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10000;
        border: 10px solid #F28B1F;
        border-left-color: rgba(#F28B1F, .2);
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: rotate 1s linear infinite;

        @keyframes rotate {
            from {
                transform: translate(-50%, -50%) rotate(0deg);
            }

            to {
                transform: translate(-50%, -50%) rotate(360deg);
            }
        }
    }

    #manager {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.4);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
        opacity: 0;
        visibility: hidden;
        transition: .15s;

        &.visible {
            opacity: 1;
            visibility: visible;
        }
    }

    #manager iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background-color: white;
        width: 430px;
        height: 660px;
    }

    #manager + header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #262A2C;
        color: white;
        padding: 14px 140px;

        img {
            display: block;
            width: auto;
            height: 66px;
        }

        > button {
            width: 30px;
            height: 30px;
            mask: url('/images/icons/cog.svg') no-repeat center;
            mask-size: contain;
            background-color: white;
            border: none;
            cursor: pointer;
        }

        nav {
            display: flex;
            flex-direction: column;
            color: black;
            position: absolute;
            right: 140px;
            top: calc(100% - var(--padding) / 2);
            background-color: #efefef;
            padding: calc(var(--padding) / 2) var(--padding);

            a,
            button {
                padding: 5px 0;
                border: none;
                background: none;
                font-size: inherit;
                text-align: left;
                color: #272b2d !important;

                &:not(:last-child) {
                    border-bottom: 1px solid black;
                }
            }
        }

        @media (max-width: 1700px) {
            padding-left: var(--padding);
            padding-right: var(--padding);

            right {
                right: var(--padding);
            }
        }
    }
</style>
